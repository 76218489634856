import React from "react";
import { Layout } from "../components/layout";
import { graphql } from "gatsby";
import { MDXRenderer } from "gatsby-plugin-mdx";
const Reference = (props) => {
    const reference = props.reference;
    return React.createElement("div", { className: "reference" },
        React.createElement("div", { className: "reference-name" },
            reference.frontmatter.title,
            ", ",
            reference.frontmatter.year),
        React.createElement(MDXRenderer, null, reference.body));
};
export const References = ({ data }) => {
    const nodes = data.allMdx.nodes;
    return React.createElement(Layout, { current: "references" },
        React.createElement("div", { className: "content" }, nodes.map((value, index) => (React.createElement(Reference, { reference: value, key: value.id })))));
};
export const query = graphql `
    query ReferenceMarkownDocuments {
        allMdx(filter: {frontmatter: {type: {eq: "reference"}}}) {
            nodes {
                frontmatter {
                    title
                    year
                }
                body
                id
            }
        }
    }`;
export default References;
